import React, { useCallback, useRef } from 'react';

import cn from 'classnames';

import ParallaxLayer from '../../design_system/parallax_layer/parallax_layer';
import OptimisedImage from '../../small_views/optimised_image/optimised_image';
import Button from '../../design_system/button/button';

import BackgroundImage from '../../../assets/images/pages/who-are-we/join_us_section/background.png';
import BackgroundImageLowDef from '../../../assets/images/pages/who-are-we/join_us_section/background_low_def.png';

import classes from './join_us_section.module.css';

const JoinUsSection = () => {
	const updateParallaxLayerHeight = useRef(null);
	const getUpdateParallaxLayerHeight = useCallback((fn) => {
		updateParallaxLayerHeight.current = fn;
	});
	const handleOptimisedImageOnReady = useCallback(() => {
		if (typeof updateParallaxLayerHeight.current === 'function') {
			updateParallaxLayerHeight.current();
		}
	}, [updateParallaxLayerHeight.current]);
	return (
		<div
			className={cn(classes.container)}
		>
			<ParallaxLayer
				{...{ getUpdateParallaxLayerHeight }}
			>
				<OptimisedImage
					className={classes.background}
					normal={BackgroundImage}
					lowDef={BackgroundImageLowDef}
					onReady={handleOptimisedImageOnReady}
					alt="Join Us Background"
				/>
			</ParallaxLayer>
			<div className={classes.content}>
				<div className={classes.title}>
					{'Envie de nous rejoindre ?'}
				</div>
				<a
					href="https://www.welcometothejungle.co/fr/companies/karnott"
					target="_blank"
					rel="noreferrer noopener"
				>
					<Button
						className={classes.button}
						color="emerald"
						variant="contained"
					>
						{'Postuler dès maintenant'}
					</Button>
				</a>
			</div>
		</div>
	);
}

export default JoinUsSection;
