import React from 'react';

import Header from '../../small_views/header/header';

import Background from '../../../assets/images/pages/who-are-we/header/background.png';
import BackgroundLowDef from '../../../assets/images/pages/who-are-we/header/background_low_def.png';

import classes from './who_are_we_header.module.css';

const WhoAreWeHeader = () => (
	<Header
		dismissButtons
		className={classes.container}
		background={Background}
		backgroundLowDef={BackgroundLowDef}
		title={<Title />}
	/>
);

const Title = () => (
	<>
		{'Une équipe passionnée,'}
		<br/>
		{'une aventure avant tout humaine,'}
		<br/>
		{'des projets ambitieux.'}
	</>
);

export default WhoAreWeHeader;
