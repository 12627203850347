import React, { useRef } from 'react';

import cn from 'classnames';

import Section from '../../design_system/section/section';
import SectionTitle from '../../design_system/section/section_title/section_title'

import Sima from '../../../assets/images/pages/who-are-we/rewards_section/logo-sima.png';
import Vinitech from '../../../assets/images/pages/who-are-we/rewards_section/logo-vinitech.png';
import LaFermeDigitale from '../../../assets/images/pages/who-are-we/rewards_section/logo-lafermedigitale.jpg';
import SIDO from '../../../assets/images/pages/who-are-we/rewards_section/logo-sido.jpg';
import AgroParisTech from '../../../assets/images/pages/who-are-we/rewards_section/logo-agroparistech.jpg';
import CerFrance from '../../../assets/images/pages/who-are-we/rewards_section/logo-cerfrance.jpg';
import Sedimaster from '../../../assets/images/pages/who-are-we/rewards_section/logo-sedimaster.png';

import classes from './rewards_section.module.css';

const RewardsSection = () => (
	<Section
		setVisibleOnScroll
		withClassName={classes.container}
	>
		{({ showSection }) => (
			<>
				<SectionTitle
					className={classes.title}
					underlineClassName={classes.underline}
					dismissUnderline={showSection}
				>
					{'Nos récompenses'}
				</SectionTitle>
				<RewardsList />
			</>
		)}
	</Section>
)

const RewardsList = () => (
	<div className={classes.rewardsList}>
		<div className={classes.mainAwards}>
			<div className={classes.simaContainer}>
				<img
					className={classes.simaPicture}
					src={Sima}
					alt="Sima Innovation Award"
					/>
			</div>
			<img
				className={cn(classes.picture, classes.vinitechPicture)}
				src={Vinitech}
				alt="Vinitech"
				/>
		</div>
		<div className={classes.otherRewards}>
			<img
				className={classes.picture}
				src={LaFermeDigitale}
				alt="laFermeDigitale"
			/>
			<img
				className={classes.picture}
				src={SIDO}
				alt="SIDO"
			/>
			<img
				className={classes.picture}
				src={AgroParisTech}
				alt="Agro Paris Tech"
			/>
			<img
				className={classes.picture}
				src={CerFrance}
				alt="CERFRANCE"
			/>
			<img
				className={classes.picture}
				src={Sedimaster}
				alt="SediMaster"
			/>
		</div>
	</div>
)

export default RewardsSection;
