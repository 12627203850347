import React from 'react';

import Section from '../../design_system/section/section';
import Button from '../../design_system/button/button';
import OptimisedImage from '../../small_views/optimised_image/optimised_image';

import BackgroundImage from '../../../assets/images/pages/who-are-we/france_map_section/background.png';
import BackgroundImageLowDef from '../../../assets/images/pages/who-are-we/france_map_section/background_low_def.png';

import classes from './france_map_section.module.css';

const FranceMapSection = () => (
	<Section withClassName={classes.container}>
		<div className={classes.backgroundContainer}>
			<OptimisedImage
				className={classes.background}
				normal={BackgroundImage}
				lowDef={BackgroundImageLowDef}
			/>
		</div>
		<div className={classes.title}>
			{'Sous les Karnott,'}
			<br />
			{'la France.'}
		</div>
		<Button
			className={classes.button}
			color="primary"
			variant="contained"
		>
			{'Et vous ?'}
		</Button>
	</Section>
);

export default FranceMapSection;
