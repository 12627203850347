import CreatedExploitationIcon from '../../../assets/images/pages/who-are-we/small_informations_section/exploitationscreees.svg';
import KilometersIcon from '../../../assets/images/pages/who-are-we/small_informations_section/kilometresparcourus.svg';
import HectaresIcon from '../../../assets/images/pages/who-are-we/small_informations_section/hectarestravailles.svg'
import DeployedCountersIcon from '../../../assets/images/pages/who-are-we/small_informations_section/compteursdeployes.svg'
import MaterialIcon from '../../../assets/images/pages/who-are-we/small_informations_section/materielsequipes.svg';
import UsageHoursIcon from '../../../assets/images/pages/who-are-we/small_informations_section/heuresdutilisation.svg';
import GpsPointsIcon from '../../../assets/images/pages/who-are-we/small_informations_section/pointsgps.svg'
import UsersIcon from '../../../assets/images/pages/who-are-we/small_informations_section/utilisateurs.svg'


const smallInformationsSectionData = Object.freeze({
	createdExploitation: {
		icon: CreatedExploitationIcon,
		label: '14 000 exploitations créées'
	},
	kilometers: {
		icon: KilometersIcon,
		label: '3,6 millions de km parcourus'
	},
	hectares: {
		icon: HectaresIcon,
		label: '450 000 hectares travaillés'
	},
	deployedCounters: {
		icon: DeployedCountersIcon,
		label: '2 050 compteurs déployés'
	},
	material: {
		icon: MaterialIcon,
		label: '5 000 matériels équipés'
	},
	usageHours: {
		icon: UsageHoursIcon,
		label: '356 700 heures d’utilisation'
	},
	gpsPoints: {
		icon: GpsPointsIcon,
		label: '600 000 000 de points gps'
	},
	users: {
		icon: UsersIcon,
		label: 'xxxxx utilisateurs'
	}
});

export default smallInformationsSectionData;
