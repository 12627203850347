import React, { useEffect, useState, memo } from 'react'

import cn from 'classnames';
import { animated, config, useTransition } from 'react-spring';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import smallInformationsSectionData from './small_informations_section_data';

import Section from '../../design_system/section/section';
import Carousel from '../../design_system/carousel/carousel';
import WithGradientLayout from '../../layouts/with_gradient_layout/with_gradient_layout';

import classes from './small_informations_section.module.css'

const SmallInformationsSection = () => (
	<WithGradientLayout className={classes.container}>
		<Section setVisibleOnScroll withClassName={classes.section}>
			{({ showSection }) => <SmallInformationsList {...{ showSection }} />}
		</Section>
	</WithGradientLayout>
)

const SmallInformationsList = ({ showSection }) => {
	const showAsCarousel = useMediaQuery('(max-width: 870px)', { defaultMatches: true });
	if (showAsCarousel) {
		return <SmallInformationsAsCarousel />
	}
	return (
		<SmallInformationsTransitions {...{ showSection }} />
	);
}

const SmallInformationsAsCarousel = memo(() => (
	<div className={classes.carouselContainer}>
		<Carousel
			rows={2}
			slidesPerRow={1}
			slidesToShow={3}
			dots={false}
			arrowClassName={classes.arrow}
			responsive={[
				{
					breakpoint: 710,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 500,
					settings: {
						slidesToShow: 1,
						centerMode: true,
						centerPadding: 0
					}
				}
			]}
		>
			{Object.entries(smallInformationsSectionData).map(([id, smallInformation]) => (
				<Item
					key={id}
					className={classes.inCarouselItem}
					{...smallInformation}
				/>
			))}
		</Carousel>
	</div>
));

const SmallInformationsTransitions = memo(({ showSection }) => {
	const [items, setItems] = useState();
	useEffect(() => {
		if (showSection && !items) {
			setItems(Object.entries(smallInformationsSectionData))
		} else if (!showSection && items) {
			setItems(null)
		}
	}, [showSection, items]);
	const transitions = useTransition(items, entry => entry && entry[0], {
		unique: true,
		trail: 120,
		config: config.slow,
		from: {
			scale: 0.9,
			opacity: 0
		},
		enter: {
			scale: 1,
			opacity: 1
		},
		leave: {
			scale: 0.9,
			opacity: 0
		}
	})
	return transitions.map(({ item, props: { scale, ...springProps }, key }) => {
		if (!item) {
			return null
		}
		return (
			<Item
				{...{ key }}
				component={animated.div}
				style={{
					transform: scale.interpolate(
						value => `scale3d(${value}, ${value}, ${value})`
					),
					...springProps
				}}
				{...item[1]}
			/>
		);
	});
});

const Item = ({
	className,
	component: Component = 'div',
	icon: Icon,
	label,
	...other
}) => (
	(
		<Component
			className={cn(classes.smallInformationItem, className)}
		>
			<div className={classes.icon}>
				<Icon />
			</div>
			<span className={classes.label}>
				{label}
			</span>
		</Component>
	)
);

export default SmallInformationsSection
