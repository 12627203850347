import React, { useCallback, useRef } from 'react';

import OptimisedImage from '../../small_views/optimised_image/optimised_image';
import Section from '../../design_system/section/section';
import ParallaxLayer from '../../design_system/parallax_layer/parallax_layer';

import BackgroundImage from '../../../assets/images/pages/who-are-we/staff_section/background.png';
import BackgroundImageLowDef from '../../../assets/images/pages/who-are-we/staff_section/background_low_def.png';

import classes from './staff_section.module.css';

const StaffSection = () => {
	const updateParallaxLayerHeight = useRef(null);
	const getUpdateParallaxLayerHeight = useCallback((fn) => {
		updateParallaxLayerHeight.current = fn;
	});
	const handleOptimisedImageOnReady = useCallback(() => {
		if (typeof updateParallaxLayerHeight.current === 'function') {
			updateParallaxLayerHeight.current();
		}
	}, [updateParallaxLayerHeight.current]);
	return (
		<Section
			className={classes.container}
		>
			<ParallaxLayer
				staticClassName={classes.static}
				{...{ getUpdateParallaxLayerHeight }}
			>
				<OptimisedImage
					className={classes.background}
					normal={BackgroundImage}
					lowDef={BackgroundImageLowDef}
					onReady={handleOptimisedImageOnReady}
					alt="Fondateurs Karnott"
				/>
			</ParallaxLayer>
		</Section>
	);
}

export default StaffSection;
