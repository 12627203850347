import React from 'react';

import HelmetLayout from '../components/layouts/helmet_layout/helmet_layout';
import MainLayout from '../components/layouts/main_layout/main_layout';

import WhoAreWeHeader from '../components/who_are_we/who_are_we_header/who_are_we_header';
import PresentationSection from '../components/who_are_we/presentation_section/presentation_section';
import StaffSection from '../components/who_are_we/staff_section/staff_section';
import SmallInformationsSection from '../components/who_are_we/small_informations_section/small_informations_section';
import JoinUsSection from '../components/who_are_we/join_us_section/join_us_section';
import FranceMapSection from '../components/who_are_we/france_map_section/france_map_section';
import RewardsSection from '../components/who_are_we/rewards_section/rewards_section';

const WhoAreWe = () => (
	<HelmetLayout>
		<MainLayout
			footerProps={{
				dismissedSections: [
					'create-demo-account',
					'trusting-customers-list'
				]
			}}
		>
			<WhoAreWeHeader />
			<PresentationSection />
			<StaffSection />
			<SmallInformationsSection />
			<JoinUsSection />
			<FranceMapSection />
			<RewardsSection />
		</MainLayout>
	</HelmetLayout>
);

export default WhoAreWe;
