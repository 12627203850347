import React from 'react';

import { animated, config, useSpring } from 'react-spring';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Section from '../../design_system/section/section';
import Card from '../../design_system/card/card';
import WithGradientLayout from '../../layouts/with_gradient_layout/with_gradient_layout';
import PlayVideo from '../../small_views/play_video/play_video';

import classes from './presentation_section.module.css';

const PresentationSection = () => {
	const slicePlayVideoText = useMediaQuery('(max-width: 375px)', { defaultMatches: true });
	return (
		<WithGradientLayout
			className={classes.container}
		>
			<Section
				withClassName={classes.section}
				setVisibleOnScroll
			>
				{({ showSection }) => (
					<div className={classes.content}>
						<div className={classes.mainText}>
							{'Pommes de terre, carottes, vin, betteraves, laitues, céréales, choux …  nous consommons tous les jours les produits de nos clients. C’est essentiel pour mieux comprendre leurs besoins !'}
						</div>
						<div className={classes.descriptionContainer}>
							<DescriptionLine show={showSection} />
							<div className={classes.descriptionTextPlayVideoContainer}>
								<div className={classes.descriptionText}>
									{'Créée par deux entrepreneurs lillois – Alexandre CUVELIER, CEO et Antoine DEQUIDT, Agriculteur – Karnott révolutionne depuis deux ans le milieu agricole avec sa solution connectée permettant de faciliter le suivi et la mutualisation du matériel agricole.'}
									<br />
									<br />
									{`Karnott est une solution connectée permettant de calculer,
										analyser et archiver automatiquement toutes les interventions réalisées par chaque équipement agricole grâce à un compteur (comprenant un GPS et de multiples capteurs) couplé à une
										application web puissante mais très simple d’utilisation.
										À la clé, la suppression des traditionnels carnets de travaux
										utilisés dans les secteurs agricoles et viticoles au bénéfice
										d’une solution numérique zéro saisie.`}
								</div>
								<div className={classes.playVideoContainer}>
									<PlayVideo
										pulseColor="light"
										title={`Ils ${slicePlayVideoText ? '' : 'nous '}présentent le projet`}
										duration="5:22"
										changeTextColorOnHover="primary"
									/>
								</div>
							</div>
						</div>
						<PresentationCard show={showSection} />
					</div>
				)}
			</Section>
		</WithGradientLayout>
	)
};

const PresentationCard = () => (
	<Card
		className={classes.card}
	>
		<span className={classes.cardTitle}>
			{'Titre à propos de l’équipe sur deux lignes ;-)'}
		</span>
		<span className={classes.cardDescription}>
			{`Rejoindre Karnott, c'est devenir membre d'une équipe
passionnée, qui aime travailler ensemble, mais aussi partager
de bons moments autour d'une bière (ou d'un jus de
pamplemousse), d'un barbecue ou encore d'un karaoké.`}
			<br />
			<br />
			{'Et comme on n\'est pas des adeptes du Ctrl+C/Ctrl+V, on est tous différents, et c\'est pour ça qu\'on s\'aime !'}
		</span>
	</Card>
);

const DescriptionLine = ({ show }) => {
	const { translation, ...otherSpringProps } = useSpring({
		translation: show ? 0 : -100,
		config: {
			...config.molasses,
			friction: 100
		}
	});
	return (
		<div className={classes.descriptionLineContainer}>
			<animated.div
				className={classes.descriptionLine}
				style={{
					transform: translation.interpolate(value => `translate3d(${value}%, 0, 0)`),
					...otherSpringProps
				}}
			/>
		</div>
	);
}

export default PresentationSection;
